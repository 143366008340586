html, body, #container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
body {
  padding-top: 50px;
}
.search {
  padding-right: 10px;
  padding-left: 15px;
  width: 100%;
}
.data-table tr {
    cursor: pointer;
    border: lightgrey;
    border-left-style: solid;
}
.data-buttons {
  position: fixed;
  right: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  margin-right: 5px;
}
.btn-mini {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 13px;
}
.table {
  padding-left: 10px;

}
#data-tabs {
  padding-top: 10px;
}
#data-tabs li a {
  padding: 5px 10px;
}
#searchclear {
    position: absolute;
    left: 260px;
    top: 10px;
    cursor: pointer;
    color: black;
}
.ui-slider-container {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
}

.ui-slider-container .ui-slider-timestamp {
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
}
.pac-container {
  z-index: 10000 !important;
}
.navbar-inverse {
  background-color: #F24F26;
  border-color: #F24F26;
}
.navbar-inverse .navbar-nav li a {
  color: #ffffff;
}
.navbar-inverse .navbar-nav > .open > a, 
.navbar-inverse .navbar-nav > .open > a:hover, 
.navbar-inverse .navbar-nav > .open > a:focus {
  color: #ffffff;
  background-color: #F24F26;
}
.navbar-inverse .navbar-nav > li a > a:focus { 
  color: #ffffff;;
}
.dropdown-menu {
  background-color: #F24F26;
}
#sidebar {
  width: 300px;
  height: 100%;
  max-width: 100%;
  float: left;
}
#map {
  width: auto;
  height: 100%;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
.table-nonfluid {
   width: auto !important;
}
#features {
  margin: 0px;
  border: none;
  border-radius: 0px;
  -webkit-box-shadow: none;
    box-shadow: none;
}
#sidebar-hide-btn {
  margin-top: -2px;
}
#aboutTabsContent {
  padding-top: 10px;
}
.lightblue {
  color: #ffffff;
}
.feature-row {
  cursor: pointer;
}
.data-row {
  cursor: pointer;
}
.sidebar-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}
.sidebar-table {
  position: relative;
  width: 100%;
  top: 0px;
  bottom: 0px;
  overflow: auto;
}
.table {
  margin-bottom: 0px;
}
.navbar .navbar-brand {
  font-weight: bold;
  font-size: 25px;
  color: #ffffff;
}
.navbar-collapse.in {
  overflow-y: hidden;
}
.navbar-header .navbar-icon-container {
  margin-right: 5px;
}
.navbar-header .navbar-icon {
  line-height: 50px;
  height: 50px;
}
.navbar-header a.navbar-icon {
  margin-left: 25px;
}
#data-detail {
    display: none;
    height: 250px;
    overflow: scroll;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
    padding-top: 5px;
    padding-left: 10px;

}
.has-feedback .form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}
@media (max-width: 992px) {
  .navbar .navbar-brand {
    font-size: 18px;
  }
  .navbar-inverse .navbar-nav li a {
    padding: 15px 8px;
  }
}
@media (max-width: 767px){
  #data-detail {
    height:200px;
  }
  #sidebar {
    width: 300px;
    display: none;
  }
  .url-break {
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
      hyphens: auto;
  }
  .dropdown-menu a i{
    color: #FFFFFF;
  }
}
/* Print Handling */
@media print {
  .navbar {
    display: none !important;
  }
  .leaflet-control-container {
    display: none !important;
  }
}
.modal-backdrop {background: none;}